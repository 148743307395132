@import 'colors', 'fonts', 'breakpoints';

$breakXLHead: 1400px;
$breakLHead: 1250px;
$breakMHead: 950px;
$breakSHead: 710px;
$breakXSHead: 400px;

#head {
  background: $lightgreen;
  min-height: 100vH;
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  overflow: hidden;
  max-width: 100vw;
  position: relative;
  color: $white;
  width: 100% !important;
  height: 100vh !important;


  .headInner {
    height: 100vh;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    padding: 0;

    @media screen and (orientation: portrait) {
      overflow: hidden;
      max-width: 100vw;
      display: block;
    }


    .left {
      width: 50%;
      z-index: 2;

      @media only screen and (max-width: $breakMHead) {
        width: 65%;
      }

      @media only screen and (max-width: $breakLHead) {
        font-size: 80px;
      }

      @media screen and (orientation: portrait) {
        width: 90%;
        display: block;
        margin: 0 auto;
        text-align: center;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%)
      }

      h1 {
        float: right;
        margin-top: 0px;
        font-size: 7.5rem;

        @media only screen and (max-width: $breakXLHead) {
          font-size: 6rem;
        }

        @media only screen and (max-width: $breakLHead) {
          font-size: 5rem;
        }

        @media only screen and (max-width: $breakMHead) {
          font-size: 4rem;
        }

        @media screen and (orientation: portrait) {
          float: left;
          font-size: 3rem;
          width: 100%;
        }

        @media only screen and (max-height: 500px) {
          font-size: 3rem;
        }

        .headPart {
          transition: left 2s, opacity 2s;
          opacity: 0;
          position: relative;
          display: block;

          &:nth-child(even) {
            left: -100px;
          }

          &:nth-child(odd) {
            left: 100px;
          }
        }

        &.visible {
          .headPart {
            opacity: 1;
            left: 0px;
          }
        }
      }
    }

    .right {
      width: 50%;
      height: 100%;

      @media screen and (orientation: portrait) {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;
        position: absolute;
        left: 0px;
      }

      .daumenfrau,
      .frau {
        height: 100%;
        position: absolute;
        bottom: 0px;
        left: 75%;
        transform: translate(-50%, 0);
        opacity: 0;
        transition: opacity 1s, height 1s;

        @media only screen and (max-width: $breakLHead) {
          position: absolute;
          height: 100%;
          left: 100%;
          transform: translate(-100%, 0);
        }

        @media only screen and (max-width: $breakSHead) {
          height: 80%;

        }

        @media screen and (orientation: portrait) {
          max-height: 60vh;
          bottom: -10vh;
          left: 50%;
          transform: translate(-50%, 0);
        }

        &.visible {
          opacity: 1;
        }
      }

      .frau {
        @media screen and (orientation: portrait) {
          left: 110%;
          transform: translate(-100%, 0);
        }
      }
    }
  }

  .fragen {
    position: absolute;
    top: 50%;
    left: calc(25% + 50px);
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.5s;
    z-index: 2000;

    @media screen and (orientation: portrait) {
      top: 5%;
      left: 50%;
      width: 100vw;
      height: 100px;
      transform: translate(-50%, 0%);
    }

    &.visible {
      opacity: 1;
    }

    li {
      list-style-type: none;
      font-size: 3.1rem;
      font-family: SuperGrotesk, sans-serif;
      font-style: normal;
      font-weight: 400;
      width: calc(50vw - 100px);
      position: relative;
      line-height: 120%;
      margin-bottom: 1.5rem;
      opacity: 0;
      transform: scale(1.2) translate(0%, 0%);
      transition: transform 0.5s, opacity 0.5s;

      @media only screen and (max-width: $breakXLHead) {
        font-size: 2.5rem;
      }

      @media only screen and (max-width: $breakLHead) {
        font-size: 2.2rem;
        width: 50vw;
      }

      @media only screen and (max-width: $breakSHead) {
        font-size: 1.5rem;
      }

      @media only screen and (max-width: $breakXSHead) {
        @media only screen and (max-height: 670px) {
          font-size: 1rem;
        }
      }

      @media only screen and (max-width: $breakMHead) {
        @media only screen and (max-height: 500px) {
          font-size: 1.5rem !important;
        }

        @media only screen and (max-height: 400px) {
          font-size: 1.2rem !important;
        }

      }

      @media screen and (orientation: portrait) {
        width: calc(100% - 20%);
        left: 10%;
      }

      .bold {
        font-weight: 700;
        transition: color 0.5s;
        color: $white;
      }

      &:before {
        content: ' ';
        background: $orange;
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        top: 26px;
        left: -20px;
        transform: translate(-50%, -50%);
        transform-origin: center center length(10px);

        @media only screen and (max-width: $breakXLHead) {
          width: 15px;
          height: 15px;
          top: 20px
        }

        @media only screen and (max-width: $breakLHead) {
          width: 15px;
          height: 15px;
          top: 18px
        }

        @media only screen and (max-width: $breakSHead) {
          left: -15px;
          width: 10px;
          height: 10px;
          top: 12px
        }

        @media only screen and (max-width: $breakXSHead) {
          @media only screen and (max-height: 670px) {
            left: -8px;
            width: 5px;
            height: 5px;
            top: 8px
          }
        }
      }

      &.visible {
        opacity: 1;
        transform: scale(1) translate(0%, 0%);

        .bold {
          font-weight: 700;
          transition: color 0.5s;
          color: $orange;
        }
      }

    }
  }

  .kennstdu {
    transition: opacity 0.5s, transform 0.5s;
    position: absolute;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    opacity: 0;
    transform: translate(-50%, -50%) scale(2);
    transform-origin: center center;
    z-index: 10;

    @media screen and (orientation: portrait) {
      white-space: normal;
    }

    h2 {
      transition: transform 0.5s;
      color: $white;
      font-size: 100px;
      font-weight: 700;
      margin: 0;
      transform: rotate(-20deg);
      background: $orange;
      padding: 30px 30px 20px 30px;
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);

      @media only screen and (max-width: $breakLHead) {
        font-size: 80px;
      }

      @media only screen and (max-width: $breakMHead) {
        font-size: 70px;
      }

      @media only screen and (max-width: $breakSHead) {
        font-size: 50px;
      }
    }

    &.visible {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      transform-origin: center center;

      h2 {
        transform: rotate(-10deg);
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
      }
    }
  }
}