@import 'colors', 'fonts', 'breakpoints';

#footer {
  text-align: center;
  font-family: SuperGrotesk;
  padding-bottom: 70px;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      display: block;
      border-right: solid 1px $darkgray;
      margin: 0 !important;
      padding: 0 !important;
      white-space: nowrap;

      &:last-child {
        border-right: none;
      }

      a {
        color: $darkgray;
        text-decoration: none;
        margin: 0 10px;
        font-size: 15px;

        &:hover {
          color: $orange;
        }
      }
    }
  }
}