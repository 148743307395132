$breakXXL: 1408px;
$breakXL: 1280px;
$breakL: 1216px;
$breakM: 1024px;
$breakS: 770px;
$breakXS: 550px;
$breakpointTable: 615px;

$maxWidthHead: 1308px;
$maxWidthSections: 934px;
