@import '_fonts', '_breakpoints', '_colors', 'sections/_head', 'sections/_benefits', 'sections/_testimonials', 'components/_buttons', 'sections/_kacheln', 'sections/_footer';

body,
html {
  font-size: 16px;
}

h1,
h2,
h3,
h4 {
  line-height: 120%;
}

h1,
h2 {
  text-transform: uppercase;
}

h1 {
  @include sansserif(bold);
  font-size: 7.5rem;
  margin-bottom: 30px;

  @media only screen and (max-width: $breakL) {
    font-size: 100px;
  }

  @media only screen and (max-width: $breakM) {
    font-size: 70px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: $breakXS) {
    font-size: 44px;
    margin-bottom: 10px;
  }

  @media only screen and (min-width: $breakXL) {
    @media only screen and (max-height: 900px) {
      font-size: 80px;
      margin-bottom: 10px;
    }
  }
}

h2 {
  @include sansserif(normal);
  font-size: 1.875rem;
  color: $orange;
  text-align: center;
  margin: 80px 0 40px 0;

  @media only screen and (max-width: $breakS) {
    margin: 60px 0 20px 0;
  }
}

h3 {
  color: $black;
  @include sansserif(normal);
  font-size: 1.5625rem;
}

p {
  font-size: 1.25rem;
  @include serif(normal);
  color: $darkgray;
  margin: 0 0 40px 0;
  line-height: 140%;
}

section {
  width: 100%;

  &:first-child {
    .inner {
      border-bottom: none;
    }
  }

  .inner {
    margin: 0 auto;
    padding-bottom: 80px;
    max-width: $maxWidthSections;
    border-bottom: solid 1px $darkgray;

    @media only screen and (max-width: $breakM) {
      margin: 0 50px;
    }

    @media only screen and (max-width: $breakS) {
      margin: 0 30px;
      padding-bottom: 60px;
    }
  }
}

.clearBoth {
  clear: both;
}

#skip {
  position: fixed;
  top: calc(100% - 40px);
  transform: translate(0%, -100%);
  right: -400px;
  z-index: 1000;
  color: $darkgreen;
  font-family: SuperGrotesk;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url('../img/next.svg');
  background-repeat: no-repeat;
  background-position: center right 5px;
  background-color: rgba(255, 255, 255, 0.5);
  background-size: 20px 20px;
  border-radius: 5px;
  display: block;
  opacity: 0;
  padding: 8px 25px 5px 10px;
  transition: opacity 0.2s, right 0.2s;

  @media only screen and (max-width: $breakXS) {
    right: 50%;
    transform: translate(50%, -50%);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &.visible {
    opacity: 1;
    right: 40px;

    @media only screen and (max-width: $breakXS) {
      right: 50%;
      transform: translate(50%, -50%);
    }
  }
}