@import 'colors', 'fonts';

.button{
  background-color: $orange;
  border:none;
  color: $white;
  @include sansserif(normal);
  cursor:pointer;
  display:inline-block;
  text-decoration:none;
  &:hover{
    background: $darkgreen;
  }
  &.XL{
    padding: 26px 30px 20px 30px;
    font-size: 2.375rem;
    line-height:120%;
    @media only screen and (max-width: $breakXS) {
      font-size: 28px;
      padding: 16px 30px 10px 30px;
    }
  }
  &.L{
    padding: 15px 20px 11px 20px;
    font-size: 28px;
    line-height:120%;
  }
  &.M{
    padding: 11px 10px 8px 10px;
    font-size: 18px;
    line-height:120%;
  }
}
