#benefits {
  .kacheln {
    height: 100px;
    background: $orange;
    position: relative;
    overflow: hidden;

    .colorSquare {
      width: 50px;
      height: 50px;
      display: block;
      background: #f00;
      position: absolute;
      border: solid 2px #fff;
      transition: all 1s;

      // @media only screen and (max-width: $breakXS) {
      //   width: 50px;
      //   height: 50px;
      // }
      &.orange {
        background: $orange;
        // animation-name: colorChangeOrange;
        // animation-duration: 6s;
        // animation-fill-mode: forwards;
        // animation-iteration-count: infinite;
      }

      &.petrol {
        background: $lightgreen;
      }

      &.gelb {
        background: $yellow;
      }

      &.gruen {
        background: $freshgreen;
      }

      &.blau {
        background: $lightblue;
      }

      &.violett {
        background: $purple;
      }

      &.fadeOut {
        animation-name: fadeOut1;
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
      }
    }
  }
}