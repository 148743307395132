
#testimonials{
  .inner{
    position:relative;
    padding-top: 80px;
    margin-bottom: 80px;
    @media only screen and (max-width: $breakS) {
      padding-bottom: 0px;
    }
    &:before, &:after{
      content: " ";
      display:block;
      background-image: url("/img/quote.svg");
      background-repeat: no-repeat;
      background-position: center center;
      width: 100px;
      height: 100px;
      position:absolute;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: #fff;
    }
    &:before{
      top: -50px;
    }
    &:after{
      bottom: -50px;
      transform: translate(-50%, 0) rotate(180deg);
    }
    #testimonialSlideHolder{
      -webkit-user-select: none;  /* Chrome all / Safari all */
      -moz-user-select: none;     /* Firefox all */
      -ms-user-select: none;      /* IE 10+ */
      user-select: none;          /* Likely future */      
      width: calc(100% + 20px);
      overflow:hidden;
      // background:#f00;
      padding-top: 60px;
      @media only screen and (max-width: $breakS) {
        width: 100%;
      }
    }
    .testimonials{
      margin-bottom:40px;
      text-align:center;
      display: flex;
      transition: margin-left 0.2s;
      margin-left: -20px;
      @media only screen and (max-width: $breakS) {
        // display:block;
      }
      .testimonial{
        position:relative;
        width: 33%;
        @media only screen and (max-width: $breakS) {
          width: 100%!important;
          margin-bottom: 100px;
          width: 100%;
        }
        .testimonialInner{
          padding: 80px 20px 30px 20px;
          position:relative;
          margin: 0 20px 0 20px;
          background: $orange10;
          height: calc(100% - 110px);
          &:after{
            content: " ";
            width: 0px;
            display:block;
            border-left: solid 40px transparent;
            border-bottom: solid 40px transparent;
            border-right: solid 40px transparent;
            border-top: solid 40px $orange10;
            position:absolute;
            bottom: -80px;
            transform: translate(-50%, 0);
            left: 50%;
            @media only screen and (max-width: $breakS) {
              transform: translate(0, 0);
              left: 20px;
            }
          }
          img{
            border-radius: 50%;
            width: 120px;
            height: 120px;
            border: solid 5px $orange10;
            position: absolute;
            transform: translate(-50%, 0);
            left: 50%;
            top: -60px;
          }
          p{
            color: $black;
            font-size: 20px;
            margin-bottom: 5px;
          }
          small{
            color: $orange;
            font-size: 16px;
            display:block;
            @include sansserif(normal);
            line-height: 110%!important;
            .passion{
              color: $darkgray;
            }
          }
        }
      }
    }
  }
}
