#benefits {
  .inner {
    max-width: 934px;

    .productLogo {

      &.kajy {
        height: 100px;
        margin: 15px 0;
      }

      &.vtv {
        height: 80px;
        margin: 25px 0;
        max-width: 100%;
      }
    }

    h2 {
      text-transform: none;
      line-height: 140%;
      color: $dark;
      font-size: 40px;

      .bold {
        display: block;
        text-transform: uppercase;
        font-size: 50px;
        margin: 5px 0 0 0;
        color: $orange;
        font-weight: 700;
      }
    }

    h3 {
      margin: 0 0 15px 0;
      text-align: center;
    }

    .products {
      display: flex;

      @media only screen and (max-width: $breakM) {
        display: block;
      }

      &.visible {

        .kasten {
          opacity: 1;

          &:first-child {
            left: 0px;
          }

          &:last-child {
            right: 0px;
          }
        }
      }

      .kasten {
        text-align: center;
        width: 50%;
        padding: 20px;
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
        margin: 20px;
        transition: all 0.2s;
        transform: rotateY(360deg);
        overflow: hidden;
        position: relative;
        opacity: 0;

        @media only screen and (max-width: $breakM) {
          width: calc(100% - 40px);
        }

        &:hover {
          transform: scale(1.1);

          a.button {}
        }

        &:first-child {
          margin-left: 0;
          left: -200px;

          @media only screen and (max-width: $breakM) {
            margin: 20px 0 40px 0 !important;
            left: 0px;
          }

        }

        &:last-child {
          margin-right: 0;
          right: -200px;

          @media only screen and (max-width: $breakM) {
            margin: 40px 0 20px 0 !important;
            left: 0px;
          }

        }

        .important {
          color: $orange;
          font-weight: 700;
          text-align: center;
          font-family: SuperGrotesk;
        }

        a.button {
          background: $lightgreen;
          color: $white;
          font-family: SuperGrotesk;
          display: block;
          font-size: 20px;
          text-align: center;
          margin: 0 -20px -20px -20px;
          padding: 24px 0 20px 0;
          text-transform: uppercase;
          font-weight: 700;

          &:hover {
            background: $orange;
          }
        }
      }
    }
  }
}